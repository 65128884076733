body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Inter-Regular";
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/(Inter-Regular)Inter-Regular.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: "Inter-Medium";
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/(Inter-Medium)Inter-Medium.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: "Inter-SemiBold";
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/(Inter-SemiBold)Inter-SemiBold.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: "Inter-Bold";
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/(Inter-Bold)Inter-Bold.ttf') format('truetype');
  font-display: block;
}