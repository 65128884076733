@mixin mobile {
    @media only screen and (max-width: 680px) {
      @content;
    }
  }
  @mixin mdpi {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }
%common-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
    padding-right: 100px;
    @include mobile {
        padding-left: 20px;
        padding-right: 20px;
        overflow-x: hidden;
    }
}
%common-button {
    min-width: 9em;
    background: #3295FF;
    border-radius: 10px;
    border: none;
    font-family: Inter-SemiBold;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: -0.53px;
    text-align: center;
    padding: 0.8em 2.2em;
    text-decoration: none;
    transition: opacity 0.2s;
    &:hover{
        opacity: 0.9;
        cursor: pointer;
    }
}
%common-title-large {
    font-family: Inter-Bold;
    font-size: 45px;
    color: #3C3C3C;
    letter-spacing: -1.5px;
}
%common-title {
    font-family: Inter-Bold;
    font-size: 35px;
    color: #3C3C3C;
    letter-spacing: -1.17px;
    line-height: 26px;
}
%common-title-medium {
    font-family: Inter-Bold;
    font-size: 30px;
    color: #3C3C3C;
    letter-spacing: -1px;
    line-height: 26px;
}
%common-title-small {
    font-family: Inter-Bold;
    font-size: 20px;
    color: #3C3C3C;
    letter-spacing: -0.67px;
    line-height: 26px;
}
%common-body {
    font-family: Inter-Medium;
    font-size: 16px;
    color: #788091;
    letter-spacing: -0.53px;
    line-height: 26px;
}
%common-body-small {
    font-family: Inter-Medium;
    font-size: 14px;
    color: #788091;
    letter-spacing: -0.47px;
    line-height: 26px;
}
$space-xxsmall: 1em;
$space-xsmall: 1.5em;
$space-small: 2.5em;
$space-medium: 3.5em;
$space-xmedium: 5.5em;
$space-large: 7.5em;
$space-xlarge: 14em;
$space-xxlarge: 16em;