@import "../../assets/styles/common.scss";

.about {
    @extend %common-container;
    padding-top: $space-xmedium;
    padding-bottom: $space-large;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mobile {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
      >div:last-of-type {
        margin-left: $space-xmedium;
        @include mobile {
          margin-left: 0;
        }
      }
      .title {
        @extend %common-title;
        padding-bottom: $space-xsmall;
      }
      .body {
        @extend %common-body;
      }
      .illustration {
        margin-right: $space-xmedium;
        position: relative;
        @include mobile {
          margin-right: 15px;
          margin-left: 15px;
          margin-top: $space-small;
        }
        .illustration-bg {
          z-index: 0;
          top: 0;
          height: 100%;
          position: absolute;
          width: 100%;
          filter: blur(40px);
          opacity: 0.33;
          background-image: linear-gradient(
            133deg,
            #ff9393 18%,
            #98b4ff 52%,
            #6ce3ec 84%,
            #64afff 100%
          );
          border-radius: 204.33px;
        }
        > img {
          position: relative;
          z-index: 1;
          width: 291px;
          height: 291px;
          margin: $space-medium $space-xmedium;
          @include mobile {
            width:100%;
            height: auto;
            margin: 0;
          }
        }
      }
    }
  }