@import "../../assets/styles/common.scss";
.footer {
    @extend %common-container;
    .inner {
        border-top: 1px solid #E5E5E5;
        padding: $space-small 0;
        display:flex;
        justify-content: space-between;
    }
    .copyright {
        font-family: Inter-Medium;
        font-size: 12px;
        color: #3C3C3C;
        letter-spacing: -0.4px;
        line-height: 26px;
    }
    .links{
        & >:first-child{
            margin-right: $space-xsmall;
        }
        >a {
            font-family: Inter-Bold;
            font-size: 12px;
            color: #3295FF;
            letter-spacing: -0.4px;
            text-align: right;
            line-height: 26px;
            >img{
                width: 20px;
                height: 20px;
            }
        }
    }
}