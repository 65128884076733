@import "../../assets/styles/common.scss";

.contact {
  overflow: hidden;
  border-top: 1px solid #E5E5E5;
  background-color: white;
  padding-top: $space-xmedium;
  padding-bottom: $space-large;
  position: relative;
  .circle {
    width: 900px;
    height: 900px;
    z-index: 0;
    bottom: 70px;
    right: -7%;
    position: absolute;
    filter: blur(45px);
    opacity: 0.33;
    background-image: linear-gradient(
      133deg,
      #ff9393 18%,
      #98b4ff 52%,
      #6ce3ec 84%,
      #64afff 100%
    );
    border-radius: 474.5px;
    @include mdpi {
      right: -12%;
    }
    @include mobile {
      bottom: -32%;
      right: -65%;
    }
  }
  .contact-inner{
    @extend %common-container;
    display: flex;
    @include mobile {
      flex-wrap: wrap;
    }
    .content {
      width: 50%;
      @include mobile {
        width: 100%;
      }
      .title {
        @extend %common-title-large;
        padding-bottom: $space-xxsmall;
      }
      .info {
        @extend %common-body;
        margin-bottom: $space-small;
        > a {
          color: #3295ff;
        }
      }
      .address-line {
        @extend %common-body;
      }
      .extra {
        display: flex;
        margin-top: $space-small;
        & > :first-child {
          border-right: 1px solid #E5E5E5;
          padding-right: $space-small;
        }
        & >:last-child {
          margin-left: $space-small;
        }
        .item {
          .title-small {
            color: #000;
              font-family: Inter-Regular;
          }
        }
      }
    }
    .form {
      width: 50%;
      @include mobile {
        width: 100%;
        margin-top: $space-medium;
      }
      filter: blur(0);
      form>:first-child {
        margin-top: 0;
      }
      .form-row {
        margin: $space-small 0;
        display: flex;
      }
      .form-submit-row {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        > button {
          @extend %common-button;
        }
      }
      input[type="text"],
      textarea {
        filter: blur(0);
        background: linear-gradient(#fff, #fff);
        border-radius: 10px;
        box-shadow: none;
        border: none;
        padding: 15px 20px;
        width: 50%;
        color: black;
        font-size: 16px;
        font-family: Inter-Regular;
        &::placeholder {
          font-family: Inter-Regular;
          font-size: 16px;
          color: #8990a1;
          letter-spacing: -0.53px;
          line-height: 26px;
        }
      }
      textarea {
        width: 100%;
      }
    }
  }
  
  
  
}
