@import "../../assets/styles/common.scss";
.hero {
    background: url("../../assets/images/bg_hero.webp") 0 0 repeat;
    background-size: cover;
    background-position: center bottom;
    color: #fff;
    padding-top: $space-medium;
    padding-bottom: $space-small;
    @include mobile {
      padding-top: $space-xsmall;
      background-position: right bottom;
      overflow-y: hidden;
    }
    .content {
      @extend %common-container;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 620px;
      @include mdpi {
        height: 550px;
      }
      @include mobile {
        height: 500px;
        overflow-y: hidden;
      }
      .nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mobile {
          flex-wrap: wrap;
        }
        .name {
          display: flex;
          > img {
            width: auto;
            height: 50px;
            padding-right: $space-small;
          }
          .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: $space-small;
            border-left: 1px solid rgba(238, 238, 238, 0.247);
            .title {
              font-family: Inter-Bold;
              font-size: 16px;
              color: #ffffff;
              letter-spacing: -0.53px;
              line-height: 18px;
            }
            .subTitle {
              font-family: Inter-Regular;
              font-size: 12px;
              color: #ffffff;
              letter-spacing: -0.53px;
              line-height: 18px;
              margin-bottom: 3px;
            }
          }
          
        }
        .contact-container {
          @include mobile {
            margin-top: 20px;
            display: none;
          }
          .contact {
            @extend %common-button;
          }
        }
      }
      .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .subtitle {
          font-family: Inter-SemiBold;
          font-size: 20px;
          color: #ffffff;
          letter-spacing: -0.67px;
        }
        .title {
          font-family: Inter-Bold;
          font-size: 97.5px;
          color: #ffffff;
          letter-spacing: -3.25px;
          background: -webkit-linear-gradient(
            0deg,
            #ff9393 0%,
            #98b4ff 36%,
            #6ce3ec 70%,
            #64afff 87%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include mobile {
            font-size: 47.5px;
          }
        }
        .cursor {
          margin-left: 20px;
          font-size: 97.5px;
          animation: blink-animation 2s steps(5, start) infinite;
          -webkit-animation: blink-animation 2s steps(5, start) infinite;
          @include mobile {
            font-size: 47.5px;
            margin-left: 8px;
          }
          @keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
          @-webkit-keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
        }
      }
      .contact-container-mobile {
        display: none;
        @include mobile {
          display: inline-block;
          margin-bottom: 15px
        }
        .contact-btn {
          @extend %common-button;
        }
      }
      .mouse-icon{
        @include mobile {
          display: none;
        }
      }
    }
  }